import React from "react"
import { Error404 } from "../components/media/Error404"
import { Box, Button } from "theme-ui"
import { Link } from "gatsby"

export default () => {
  return (
    <React.Fragment>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Error404 />
      </Box>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Link to="/">
          <Button
            sx={{
              cursor: "pointer",
              fontFamily: "body",
              textTransform: "capitalize",
              backgroundColor: "primary",
            }}
          >
            Home
          </Button>
        </Link>
      </Box>
    </React.Fragment>
  )
}
